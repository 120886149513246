import { Outlet } from 'react-router-dom';

import './index.css';

const AuthenticationLayout = () => {

    return (
        <div className="AuthRoot">
            <Outlet/>
        </div>
    );
};

export default AuthenticationLayout;
