import './style.css';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useMediaQuery } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import { motion } from 'framer-motion';

import { menuState } from '@/recoil/store/menu';

import MRD_LOGO from '@/assets/mrd_logo03.png';
import CHAT_ICON from '@/assets/chat_icon.svg';
import MENU_HAMBURGER from '@/assets/menu_hamburger.svg';
import MENU_CLOSE from '@/assets/ico_menuclose.svg';

// 네비게이션 메뉴
const NAVIGATION_MENU = [
    {
        label: '미스터디벨로 소개',
        path: '/intro',
        dropdown: [
            { label: '미스터디벨로 철학', path: '/intro' },
            { label: '미스터디벨로 차별점', path: '/difference' },
            { label: '작업 프로세스', path: '/process' },
            { label: '개발사 선택 시 주의사항', path: '/caution' }
        ]
    },
    {
        label: '서비스',
        path: '/first_level',
        dropdown: [
            { label: 'Lv1.기획', path: '/first_level' },
            { label: 'Lv2.개발', path: '/second_level' },
            { label: 'Lv3.기획,디자인,개발', path: '/third_level' }
        ]
    },
    { label: '포트폴리오', path: '/portfolio' },
    { label: 'FAQ', path: '/faq' }
];

const Navigation = () => {
    // 현재 호버된 메뉴 상태
    const [hoveredMenu, setHoveredMenu] = useState<number | null>(null);
    // 햄버거 메뉴 상태 (전역)
    const [isMenuOpen, setIsMenuOpen] = useRecoilState<boolean>(menuState);

    // 라우터 네비게이션
    const router = useNavigate();

    // 반응형 미디어 쿼리
    const mediumSize = useMediaQuery('(max-width: 1024px)');

    // 햄버거 메뉴 토글 함수
    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // 문의하기 페이지로 이동
    const handleConsulting = () => {
        router('/inquiry');
    };

    // mediumSize가 true일 때만 햄버거 메뉴 버튼을 렌더링
    useEffect(() => {
        if (!mediumSize) {
            setIsMenuOpen(false);
        }
    }, [mediumSize]);

    return (
        <header
            className="nav-main"
            style={isMenuOpen ? { backgroundColor: '#000' } : {}}
        >
            <div className="nav-main-box">
                <div className="nav-logo">
                    <Link to="/">
                        <img
                            src={MRD_LOGO}
                            alt="logo"
                            width={184}
                            height={28}
                        />
                    </Link>
                </div>
                <nav className="nav-menu">
                    <ul className="nav-menu-item">
                        {NAVIGATION_MENU.map((menu, index) => (
                            <li
                                key={index}
                                onMouseEnter={() => setHoveredMenu(index)} // 메뉴 항목에 호버시 상태 업데이트
                                onMouseLeave={() => setHoveredMenu(null)} // 마우스가 떠날 때 상태 초기화
                            >
                                <Link to={menu.path}>{menu.label}</Link>
                                {hoveredMenu === index && menu.dropdown && (
                                    <motion.div
                                        className="nav-menu-item-dropdown"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 0.5 }}
                                    >
                                        {menu.dropdown.map((item, idx) => (
                                            <Link to={item.path} key={idx}>
                                                {item.label}
                                            </Link>
                                        ))}
                                    </motion.div>
                                )}
                            </li>
                        ))}
                    </ul>

                    <button className="nav-btn" onClick={handleConsulting}>
                        <img
                            src={CHAT_ICON}
                            alt="menu"
                            width={18}
                            height={18}
                        />
                        <span>문의하기</span>
                    </button>
                </nav>

                {mediumSize && (
                    <button className="md-btn" onClick={handleMenuToggle}>
                        <img
                            src={isMenuOpen ? MENU_CLOSE : MENU_HAMBURGER}
                            alt="menu"
                        />
                    </button>
                )}
            </div>
        </header>
    );
};

export default Navigation;
