import { lazy } from 'react';

import AuthLayout from '@/layout/authenticationLayout';
import Loadable from '@/components/Loadable';

const SingIn = Loadable(lazy(() => import('@/pages/app/authentication/signIn')));

const AuthenticationRoutes = {
    path: '/auth',
    element: <AuthLayout />,
    children: [
        {
            path: 'sign_in',
            element: <SingIn />
        }
    ]
};

export default AuthenticationRoutes;
