import AppRoutes from './appRoutes';
import AuthenticationRoutes from './authenticationRoutes';

import { useRoutes } from 'react-router-dom';

const Routes = () => {
    return useRoutes([AppRoutes, AuthenticationRoutes]);
};

export default Routes;
