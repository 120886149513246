import './index.css';

import { useEffect, useRef, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { menuState } from '@/recoil/store/menu';

import Navigation from '@/components/Navigation';
import Footer from '@/components/Footer';

const AdminLayout = () => {
    // 토글 리스트 Ref
    const listRef = useRef<Record<string, HTMLUListElement | null>>({});
    // 햄버거 메뉴 상태 (전역)
    const [isMenuOpen, setIsmenuOpen] = useRecoilState<boolean>(menuState);
    // 현재 활성화된 메뉴 아이템 상태
    const [activeMenu, setActiveMenu] = useState<string>('');

    const { pathname } = useLocation();

    // 메뉴 아이템 클릭 핸들러
    const handleMenuItemClick = (menuItem: string) => {
        setActiveMenu(activeMenu === menuItem ? '' : menuItem);
    };

    // 메뉴 높이 조정 useEffect
    useEffect(() => {
        const updatedHeight = () => {
            Object.keys(listRef.current).forEach(key => {
                const el = listRef.current[key];
                if (el) {
                    el.style.maxHeight =
                        activeMenu === key ? `${el.scrollHeight}px` : '0px';
                }
            });
        };
        updatedHeight();
    }, [activeMenu]);

    useEffect(() => {
        setIsmenuOpen(false);
        setActiveMenu('');
    }, [pathname]);

    return (
        <div className="AppRoot">
            <Navigation />
            <main className="main-content-wrapper">
                <Outlet />
            </main>
            <Footer />

            {isMenuOpen && (
                <div className="menu-overlay">
                    <div className="menu-box">
                        <ul>
                            <li>
                                <a
                                    href="#"
                                    className={
                                        activeMenu === 'intro' ? 'active' : ''
                                    }
                                    onClick={() => handleMenuItemClick('intro')}
                                >
                                    미스터디벨로 소개
                                </a>
                                <ul
                                    ref={el => (listRef.current['intro'] = el)}
                                    style={
                                        activeMenu === 'intro'
                                            ? { borderTop: '1px solid #e5e5e5' }
                                            : { borderTop: 'none' }
                                    }
                                >
                                    <li>
                                        <Link to="/intro">
                                            미스터 디벨로 철학
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/difference">
                                            미스터 디벨로 차별점
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/process">작업 프로세스</Link>
                                    </li>
                                    <li>
                                        <Link to="/caution">
                                            개발사 선택 시 주의사항
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a
                                    href="#"
                                    className={
                                        activeMenu === 'service' ? 'active' : ''
                                    }
                                    onClick={() =>
                                        handleMenuItemClick('service')
                                    }
                                >
                                    서비스
                                </a>
                                <ul
                                    ref={el =>
                                        (listRef.current['service'] = el)
                                    }
                                    style={
                                        activeMenu === 'service'
                                            ? { borderTop: '1px solid #e5e5e5' }
                                            : { borderTop: 'none' }
                                    }
                                >
                                    <li>
                                        <Link to="/first_level">Lv1.기획</Link>
                                    </li>
                                    <li>
                                        <Link to="/second_level">Lv2.개발</Link>
                                    </li>
                                    <li>
                                        <Link to="/third_level">
                                            Lv3.기획,디자인,개발
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/portfolio" className="no-submenu">
                                    포트폴리오
                                </Link>
                            </li>
                            <li>
                                <Link to="/faq" className="no-submenu">
                                    FAQ
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <Link to="/inquiry">문의하기</Link>
                </div>
            )}
        </div>
    );
};

export default AdminLayout;
