import { lazy } from 'react';
import AppLayout from '@/layout/appLayout';
import Loadable from '@/components/Loadable';

const Home = Loadable(lazy(() => import('@/pages/app/home')));
const Philosophy = Loadable(lazy(() => import('@/pages/app/Philosophy')));
const Different = Loadable(lazy(() => import('@/pages/app/Different')));
const Process = Loadable(lazy(() => import('@/pages/app/Process')));
const Precaution = Loadable(lazy(() => import('@/pages/app/Precaution')));
const LevelOne = Loadable(lazy(() => import('@/pages/app/LevelOne')));
const LevelTwo = Loadable(lazy(() => import('@/pages/app/LevelTwo')));
const LevelThree = Loadable(lazy(() => import('@/pages/app/LevelThree')));
const FAQ = Loadable(lazy(() => import('@/pages/app/FAQ')));
const Inquiry = Loadable(lazy(() => import('@/pages/app/Inquiry')));
const Portfolio = Loadable(lazy(() => import('@/pages/app/Portfolio')));
const PortfolioDetail = Loadable(
    lazy(() => import('@/pages/app/PortfolioDetail'))
);

const AppRoutes = {
    path: '/',
    element: <AppLayout />,
    children: [
        {
            index: true,
            element: <Home />
        },
        {
            path: 'intro',
            element: <Philosophy />
        },
        {
            path: 'difference',
            element: <Different />
        },
        {
            path: 'process',
            element: <Process />
        },
        {
            path: 'caution',
            element: <Precaution />
        },
        {
            path: 'first_level',
            element: <LevelOne />
        },
        {
            path: 'second_level',
            element: <LevelTwo />
        },
        {
            path: 'third_level',
            element: <LevelThree />
        },
        {
            path: 'faq',
            element: <FAQ />
        },
        {
            path: 'inquiry',
            element: <Inquiry />
        },
        {
            path: 'portfolio',
            element: <Portfolio />
        },
        {
            path: 'portfolio/:id',
            element: <PortfolioDetail />
        }
    ]
};

export default AppRoutes;
