import './style.css';

import { useRecoilValue } from 'recoil';
import { menuState } from '@/recoil/store/menu';
import { Link } from 'react-router-dom';

import MR_LOGO from '@/assets/ico_ft_logo.svg';

import CHAT_ICON from '@/assets/ico_q_chat.svg';
import MAP_ICON from '@/assets/ico_q_map.svg';
import BLOG_ICON from '@/assets/ico_q_blog.svg';
import INSTA_ICON from '@/assets/ico_q_insta.svg';

const Footer = () => {
    const isMenuOpen = useRecoilValue<boolean>(menuState);

    // isMenuOpen 값이 true이면 footer를 렌더링하지 않는다.
    if (isMenuOpen) return null;

    return (
        <footer className="footer">
            <div className="quick-menu">
                <ul>
                    <li style={{ backgroundImage: `url(${CHAT_ICON})` }}>
                        <Link
                            to="/inquiry"
                            title="문의하기"
                            className="chat-icon"
                        >
                            문의하기
                        </Link>
                    </li>
                    <li style={{ backgroundImage: `url(${MAP_ICON})` }}>
                        <a
                            href="https://naver.me/5wHvTBLL"
                            target="_blank"
                            rel="noreferrer"
                            title="오시는길"
                            className="map-icon"
                        >
                            오시는길
                        </a>
                    </li>
                    <li style={{ backgroundImage: `url(${BLOG_ICON})` }}>
                        <a
                            href="https://blog.naver.com/mrdevello"
                            target="_blank"
                            rel="noreferrer"
                            title="블로그"
                            className="blog-icon"
                        >
                            블로그
                        </a>
                    </li>
                    <li style={{ backgroundImage: `url(${INSTA_ICON})` }}>
                        <a
                            href="https://www.instagram.com/mr.devello/"
                            target="_blank"
                            rel="noreferrer"
                            title="인스타그램"
                            className="insta-icon"
                        >
                            인스타그램
                        </a>
                    </li>
                </ul>
            </div>

            <div className="footer-wrapper">
                <p className="footer-logo">
                    <img src={MR_LOGO} alt="logo" />
                </p>

                <div className="footer-sns">
                    <a
                        href="/inquiry"
                        style={{ backgroundImage: `url(${CHAT_ICON})` }}
                    >
                        문의하기
                    </a>
                    <a
                        href="https://naver.me/5wHvTBLL"
                        target="_blank"
                        rel="noreferrer"
                        style={{ backgroundImage: `url(${MAP_ICON})` }}
                    >
                        오시는길
                    </a>
                    <a
                        href="https://blog.naver.com/mrdevello"
                        target="_blank"
                        rel="noreferrer"
                        style={{ backgroundImage: `url(${BLOG_ICON})` }}
                    >
                        블로그
                    </a>
                    <a
                        href="https://www.instagram.com/mr.devello/"
                        target="_blank"
                        rel="noreferrer"
                        style={{ backgroundImage: `url(${INSTA_ICON})` }}
                    >
                        인스타그램
                    </a>
                </div>

                <address className="footer-address">
                    <p>
                        업체명 : 미스터 디벨로 | Tel.{' '}
                        <a href="tel:070-8860-0092"> 070-8860-0092</a> |
                        대표자명 : 송익주 | 사업자등록번호 : 507-87-02801
                    </p>
                    <p>
                        주소 : 서울시 마포구 상수동 341-6 3층 | 정보책임자 :
                        우요한 | 이메일 :{' '}
                        <a href="mailto:contact@mrdevello.com">
                            contact@mrdevello.com
                        </a>
                    </p>
                </address>
            </div>

            <p className="copy">TS (C) 2023. 미스터디벨로. ALL RIGHT</p>
        </footer>
    );
};

export default Footer;
